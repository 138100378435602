<script setup lang="ts">
import {useSettingsStore} from "~/stores/settings";
import {useElementor, useWp} from "~/composables/Redmonkey";
import {NuxtLink} from "#components";

const {$config} = useNuxtApp();
const {
  getSettingsValue: getGeneralSettingValue,
  isExternalLink: isExternalLinkWp
} = useWp()

const {
  isExternalLink,
  isLinkTargetBlank,
  getSettingsValue,
  isThatDefault
} = useElementor();

const settingsStore = useSettingsStore();

const props = defineProps({
  element: Object
})

const getSlogan = () => {
  let slogan = '';
  if (!isThatDefault(props?.element, 'use_default_slogan')) {
    slogan = getSettingsValue(props.element, 'slogan');
  }

  if (!slogan) {
    slogan = getGeneralSettingValue(settingsStore, 'globalSlogal', 'redmonkeySettings');
  }

  return slogan;
}

const getDemoButton = () => {
  let button = {
    url: '',
    target: '',
    title: '',
    component: '' as any
  };

  if (!isThatDefault(props?.element, 'use_default_button')) {
    button = {
      url: getSettingsValue(props?.element, 'demo_button_url', 'url'),
      target: isLinkTargetBlank(props?.element, 'demo_button_url') ? '_blank' : '_self',
      title: getSettingsValue(props?.element, 'demo_button_text'),
      component: isExternalLink(props?.element, 'demo_button_url') ? 'a' : NuxtLink
    }
  }

  if (!button?.url) {
    const acfButton = getGeneralSettingValue(settingsStore, 'globalDemoButton', 'redmonkeySettings');
    button = {
      ...acfButton,
      component: isExternalLinkWp(acfButton.url) ? 'a' : NuxtLink
    }
  }

  return button;
}

const getTags = () => {
  let tags = undefined as [{ tag: string }] | undefined;

  if (!isThatDefault(props?.element, 'use_default_tags')) {
    tags = getSettingsValue(props?.element, 'tags')
  }

  if (!tags) {
    tags = getGeneralSettingValue(settingsStore, 'globalTags', 'redmonkeySettings')
  }

  return tags;
}

const getDescription = () => {
  let description = '';

  if (!isThatDefault(props?.element, 'use_default_desc')) {
    description = getSettingsValue(props?.element, 'description')
  }

  if (!description) {
    description = getGeneralSettingValue(settingsStore, 'globalDescription', 'redmonkeySettings')
  }

  return description;
}

const getImage = () => {
  return {
    url: getSettingsValue(props?.element, 'image', 'url') || `${$config.public.wpContentPlugins}elementor/assets/images/placeholder.png`,
    alt: getSettingsValue(props?.element, 'image', 'alt') || getSlogan()
  }
}

const getTitle = () => {
  return getSettingsValue(props?.element, 'title');
}

const getSubTitle = () => {
  return getSettingsValue(props?.element, 'sub_title');
}

const getCaption = () => {
  return getSettingsValue(props?.element, 'caption');
}

const getStudents = () => {
  let students = undefined as { title: string, count: string | number } | undefined;

  if (!isThatDefault(props?.element, 'use_default_students_count')) {
    students = {
      title: getSettingsValue(props?.element, 'students_count_caption'),
      count: `${getSettingsValue(props?.element, 'students_count')}+`
    }
  }

  if (!students) {
    students = {
      title: getGeneralSettingValue(settingsStore?.redmonkeySettings, 'satisfiedStudentsTitle', 'counts'),
      count: `${getGeneralSettingValue(settingsStore?.redmonkeySettings, 'satisfiedStudents', 'counts')}+`
    }
  }

  return students;
}

const getLessons = () => {
  let lessons = undefined as { title: string, count: string | number } | undefined;

  if (!isThatDefault(props?.element, 'use_default_lessons_count')) {
    lessons = {
      title: getSettingsValue(props?.element, 'lessons_count_caption'),
      count: `${getSettingsValue(props?.element, 'lessons_count')}+`
    }
  }

  if (!lessons) {
    lessons = {
      title: getGeneralSettingValue(settingsStore?.redmonkeySettings, 'lessonsTitle', 'counts'),
      count: `${getGeneralSettingValue(settingsStore?.redmonkeySettings, 'lessons', 'counts')}+`
    }
  }

  return lessons;
}

const getInWorkCount = () => {
  let inWork = undefined as { title: string, count: string | number } | undefined;

  if (!isThatDefault(props?.element, 'use_default_in_work_count')) {
    inWork = {
      title: getSettingsValue(props?.element, 'in_work_count_caption'),
      count: `${getSettingsValue(props?.element, 'in_work_count')}+`
    }
  }

  if (!inWork) {
    inWork = {
      title: getGeneralSettingValue(settingsStore?.redmonkeySettings, 'studentsFoundTheWorkTitle', 'counts'),
      count: `${getGeneralSettingValue(settingsStore?.redmonkeySettings, 'studentsFoundTheWork', 'counts')}+`
    }
  }

  return inWork;
}
</script>
<template>
  <div class="main-banner">
    <div class="main-banner-content">
      <div class="slogan" v-if="getSlogan()">
        <span class="slogan-title" v-html="getSlogan()"></span>
        <span class="slogan-button" v-if="getDemoButton().component">
          <component
              :is="getDemoButton().component"
              :to="getDemoButton().url"
              :href="getDemoButton().url"
              :target="getDemoButton().target"
          >{{ getDemoButton().title }}</component>
        </span>
      </div>
      <div class="tags-description flex wrap middle justify">
        <div class="tags">
          <ul>
            <li v-for="tag in getTags()" :key="tag.tag"><span>{{ tag.tag }}</span></li>
          </ul>
        </div>
        <div class="description" v-if="getDescription()">
          <div class="description-content" v-html="getDescription()"></div>
        </div>
      </div>
      <div class="skew-image-info flex wrap justify">
        <div class="skew-image" v-if="getImage().url" @click="$router.push({hash: '#demo-form'})">
          <picture>
            <img :src="getImage().url" :alt="getImage().alt"/>
            <img class="mask" src="/images/mask.png"/>
          </picture>
          <span class="figure-1"></span>
          <span class="figure-2">
            <span class="figure-2"></span>
            <span class="figure-2"></span>
          </span>
        </div>
        <div class="skew-info">
          <span class="figure-4"></span>
          <span class="figure-5"></span>
          <span class="figure-6"></span>
          <div class="skew-info-content justify flex wrap">
            <div class="skew-info-part info-part-1">
              <div class="info-part-1-content">
                <h2>{{ getTitle() }}</h2>
                <p>{{ getCaption() }}</p>
              </div>
            </div>
            <div class="skew-info-part info-part-2">
              <h3>{{ getSubTitle() }}</h3>
              <ul>
                <li v-if="getStudents()">
                  <span class="count">{{ getStudents().count }}</span>
                  <span class="title" v-html="getStudents().title"></span>
                </li>
                <li v-if="getLessons()">
                  <span class="count">{{ getLessons().count }}</span>
                  <span class="title" v-html="getLessons().title"></span>
                </li>
                <li v-if="getInWorkCount()">
                  <span class="count">{{ getInWorkCount().count }}</span>
                  <span class="title" v-html="getInWorkCount().title"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/widgets/main_banner";
</style>